import { useState } from 'react'
import './App.scss'

function App() {
    const [blueScore, setBlueScore] = useState(0)
    const [redScore, setRedScore] = useState(0)

    function resetAll() {
        setBlueScore(0)
        setRedScore(0)
    }

    // if (blueScore < 0) {
    //     setBlueScore(0)
    // }

    // if (redScore < 0) {
    //     setRedScore(0)
    // }

    return (
        <div className="App">
            <div className="App__Blue">
                <p className="App__Blue__Score">{blueScore}</p>
                <div className="App__Blue__Buttons">
                    <button onClick={() => setBlueScore(blueScore + 1)}>
                        Spin
                    </button>
                    <button onClick={() => setBlueScore(blueScore + 2)}>
                        Over
                    </button>
                    <button onClick={() => setBlueScore(blueScore + 2)}>
                        Burst
                    </button>
                    <button onClick={() => setBlueScore(blueScore + 3)}>
                        Xtreme
                    </button>
                    <button onClick={() => setBlueScore(blueScore - 1)}>
                        -1
                    </button>
                </div>
            </div>
            <div className="App__Mid">
                <button onClick={resetAll}>Reset</button>
            </div>
            <div className="App__Red">
                <p className="App__Red__Score">{redScore}</p>
                <div className="App__Red__Buttons">
                    <button onClick={() => setRedScore(redScore + 1)}>
                        Spin
                    </button>
                    <button onClick={() => setRedScore(redScore + 2)}>
                        Over
                    </button>
                    <button onClick={() => setRedScore(redScore + 2)}>
                        Burst
                    </button>
                    <button onClick={() => setRedScore(redScore + 3)}>
                        Xtreme
                    </button>
                    <button onClick={() => setRedScore(redScore - 1)}>
                        -1
                    </button>
                </div>
            </div>
        </div>
    )
}

export default App
